import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

export const NotWrapper = styled.div`
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--texts);
`

export const NotFoundImage = styled(Img)`
  width: 20rem;
`

export const NotFoundText = styled.h1`
  margin-top: 16px;
  font-size: 5em;
  font-weight: 500;

  ${media.lessThan("large")`
    font-size: 2.5rem;
  `}
`
