import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as S from "./styled"

export default function NotFound() {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "404.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.NotWrapper>
      <S.NotFoundImage fluid={image.childImageSharp.fluid}></S.NotFoundImage>
      <S.NotFoundText>404 - Page not found</S.NotFoundText>
    </S.NotWrapper>
  )
}
